/* @import '~bootstrap/dist/css/bootstrap.min.css'; */
@import './custom.bootstrap.scss';
// @import '~katex/dist/katex.min.css';

// export const device = {
//     xs: `@media (min-width: 380.98px)`,
//     sm: `@media (min-width: 575.98px)`,
//     md: `@media (min-width: 767.98px)`,
//     lg: `@media (min-width: 991.98px)`,
//     xl: `@media (min-width: 1199.98px)`,
// };

// Change this is you want to change input borders
// .form-control:focus {
//     border-color: #83b8f3;
//     box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #83b8f3;
//   }

.form-control::-webkit-input-placeholder {
    color: #c3ced6;
    font-weight: 200;
}
.form-control:-moz-placeholder {
    color: #c3ced6;
    font-weight: 200;
}
.form-control::-moz-placeholder {
    color: #c3ced6;
    font-weight: 200;
}
.form-control::placeholder {
    color: #c3ced6;
    font-weight: 200;
}
.form-control:-ms-input-placeholder {
    color: #c3ced6;
    font-weight: 200;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

body {
    font-family: 'Roboto', sans-serif !important;
    color: #5b6b79;
}

#__next,
html,
body {
    height: 100%;
    position: relative;
}

.z-depth-0 {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

h1 {
    font-weight: 600;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Comfortaa', sans-serif !important;
}

@media (max-width: 768px) {
    h1 {
        font-size: 32px;
    }
    h2 {
        font-size: 26px;
    }
    h3 {
        font-size: 23px;
    }
    h4 {
        font-size: 20px;
    }
    h5 {
        font-size: 17px;
    }
    h6 {
        font-size: 14px;
    }
    span,
    p {
        font-size: 15px;
    }
}

@media (max-width: 576px) {
    h1 {
        font-size: 24px;
    }
    h2 {
        font-size: 22px;
    }
    h3 {
        font-size: 20px;
    }
    h4 {
        font-size: 18px;
    }
    h5 {
        font-size: 16px;
    }
    h6 {
        font-size: 14px;
    }
    span,
    p {
        font-size: 14px;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 1.5rem;
    padding-top: 0.5rem;
    color: #2b363f;
}

/* 2dp elevation modified*/
.z-depth-1 {
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.z-depth-1-half {
    -webkit-box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12),
        0 3px 1px -1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
}

/* 6dp elevation modified*/
.z-depth-2 {
    -webkit-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
        0 2px 4px -1px rgba(0, 0, 0, 0.3);
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
}

/* 12dp elevation modified*/
.z-depth-3 {
    -webkit-box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12),
        0 5px 5px -3px rgba(0, 0, 0, 0.2);
    box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12),
        0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

/* 16dp elevation */
.z-depth-4 {
    -webkit-box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12),
        0 8px 10px -7px rgba(0, 0, 0, 0.2);
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12),
        0 8px 10px -7px rgba(0, 0, 0, 0.2);
}

/* 24dp elevation */
.z-depth-5 {
    -webkit-box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12),
        0 11px 15px -7px rgba(0, 0, 0, 0.2);
    box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12),
        0 11px 15px -7px rgba(0, 0, 0, 0.2);
}
